html {
  overflow-x: hidden;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

body {
  margin: 0;
}

html, body, #root {
  height: 100%;
}
